import { render, staticRenderFns } from "./PropertyList.vue?vue&type=template&id=637d2132&scoped=true"
import script from "./PropertyList.vue?vue&type=script&lang=js"
export * from "./PropertyList.vue?vue&type=script&lang=js"
import style0 from "./PropertyList.vue?vue&type=style&index=0&id=637d2132&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637d2132",
  null
  
)

export default component.exports