<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="details-card">
          <v-carousel hide-delimiters cycle :interval="5000">
            <v-carousel-item
              v-for="(image, index) in property.images"
              :key="index"
            >
              <cachedImage :src="image" class="details-img"></cachedImage>
            </v-carousel-item>
          </v-carousel>
          <v-card-title
            >{{ property.address.streetNumber }} {{ property.address.street }},
            {{ property.address.suburb }}</v-card-title
          >
          <v-card-subtitle>{{ property.price }}</v-card-subtitle>
          <v-card-text>
            <div>
              <strong>Description:</strong>
              <p>{{ property.description }}</p>
            </div>
            <div>
              <strong>Inspection Times:</strong>
              <div
                v-for="(inspection, index) in property.inspectionTimes"
                :key="index"
              >
                Start: {{ formatDate(inspection.startDateTime) }} <br />
                End: {{ formatDate(inspection.endDateTime) }}
              </div>
            </div>
            <div>
              <strong>Features:</strong>
              <ul>
                <li
                  v-for="(value, feature) in property.features"
                  :key="feature"
                >
                  {{ feature.replace(/([A-Z])/g, " $1").trim() }}: {{ value }}
                </li>
              </ul>
            </div>
          </v-card-text>
          <v-btn @click="$router.go(-1)" color="primary">Back</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";
import cachedImage from "./cachedImage.vue";

export default Vue.extend({
  name: "PropertyDetails",
  components: { cachedImage },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      property: null,
    };
  },
  methods: {
    fetchProperty() {
      fetch(`/properties.json`)
        .then((response) => response.json())
        .then((data) => {
          this.property = data.find((p) => p.id === this.id);
        })
        .catch((error) => {
          console.error("Error fetching property:", error);
        });
    },
    formatDate(timestamp) {
      return dayjs(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
  },
  mounted() {
    this.fetchProperty();
  },
});
</script>

<style scoped>
.details-card {
  margin-top: 20px;
  border-radius: 32px; /* More rounded corners for Material 3 */
}

.details-img {
  object-fit: cover;
  border-radius: 32px; /* More rounded corners for images */
}

.v-card-text {
  font-size: 18px;
}

.v-card-title {
  font-size: 32px;
}

.v-card-subtitle {
  font-size: 28px;
}

.v-btn {
  margin-top: 20px;
}
</style>
