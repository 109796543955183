<template>
  <tr style="height: 100px !important" :class="rowClass" class="tableFont">
    <td class="text-center">
      {{ formatInspectionTimes(property.inspectionTimes) }}
    </td>
    <td class="text-center">
      {{ property.address?.streetNumber }} {{ property.address?.street }},
      {{ property.address?.suburb }}
    </td>
    <td class="text-center">
      {{ property.features?.bedrooms || 0 }}
    </td>
    <td class="text-center">
      {{ property.features?.toilets || 0 }}
    </td>
    <td class="text-center">
      {{ property.features?.garages || 0 }}
    </td>
    <td class="text-center">
      {{ property.price || 0 }}
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";

export default Vue.extend({
  props: {
    index: Number,
    property: {
      default: {},
    },
  },
  computed: {
    rowClass() {
      return this.index % 2 === 0 ? "even-row" : "odd-row";
    },
  },
  methods: {
    formatDate(timestamp) {
      return dayjs(timestamp).format("h:mm a");
    },
    formatInspectionTimes(inspectionTimes) {
      if (!inspectionTimes) return "N/A";
      if (inspectionTimes.length > 1) inspectionTimes = [inspectionTimes[0]];
      return inspectionTimes
        .map((inspection) => {
          const start = this.formatDate(inspection.startDateTime);
          const end = dayjs(inspection.endDateTime).format("h:mm a");
          return `${start} - ${end}`;
        })
        .join(", ");
    },
  },
});
</script>
<style>
.tableFont > td {
  font-size: 1.4rem !important;
  font-weight: 500 !important;
}
</style>
