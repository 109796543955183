<template>
  <v-app>
    <v-main>
      <v-container fill-height v-if="!paginatedProperties?.length">
        <v-row justify="center" class="title-container">
          <h1>LOADING...</h1>
        </v-row>
      </v-container>
      <v-container v-else fluid>
        <v-row
          style="background-color: rgba(0, 0, 0, 0.2)"
          justify="center"
          class="title-container display-2 ma-5 pa-10"
        >
          <h2>
            {{ media.header }}
          </h2>
        </v-row>
        <v-row v-if="inspectionDate" justify="center" class="title-container">
          <h2>{{ inspectionDate }}</h2>
        </v-row>

        <router-view :properties="paginatedProperties" />
        <v-row justify="center">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="10"
            :circle="true"
            :color="'primary'"
            :active-class="'active'"
          ></v-pagination>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getProperties } from "./fns";
export default {
  name: "App",
  data() {
    return {
      media: {},
      properties: [],
      page: 1,
      itemsPerPage: 6, // Default value
    };
  },
  created() {
    window.loadData = this.loadData;
    // //window.name = ``;
    //window.name =
    //  '{"id":"hOksVTRWTXcP229HJLzw","email":"support@easysignage.com","brand":"next","header":"Open For Inspection","agency_ids":["rex-00270","vaultre-5404","rex-00909","agentbox-265","agentbox-1106OBRNREBRWCK"],"listing_count":[0,23],"duration":5,"listing_type":["rental","residential"]}';
    if (window.name) {
      const data = JSON.parse(window.name);
      if (data) this.loadData(data);
    }
    this.setGlobalFontColor("#FFFFFF"); // Example color
    this.setGlobalBackgroundColor("#0d2e5d");
    // this.setGlobalFontColor("#0d2e5d"); // Example color
    // this.setGlobalBackgroundColor("#FFFFFF");
    this.listToJS();
  },
  computed: {
    inspectionDate() {
      for (const p of this.properties) {
        if (p.inspectionDates) return p.inspectionDates[0];
      }
      return false;
    },

    paginatedProperties() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = this.page * this.itemsPerPage;
      return this.properties.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.properties.length / this.itemsPerPage);
    },
  },

  methods: {
    listToJS() {
      window.addEventListener(
        "message",
        function (event) {
          if (event.data) eval(event.data); // Be careful with eval, only use it if you trust the source
        },
        false
      );
    },
    setGlobalBackgroundColor(color) {
      window.backgroundColor = color;

      this.$vuetify.theme.themes.light.background = color;
      this.$vuetify.theme.themes.dark.background = color;
    },
    setGlobalFontColor(color) {
      window.fontColor = color;
      this.$vuetify.theme.themes.light.primary = color;
      this.$vuetify.theme.themes.light.onBackground = color;
      this.$vuetify.theme.themes.light.color = color;
    },
    loadData(media) {
      this.media = typeof media === "object" ? media : JSON.parse(media);
      // const listing_type = media.listing_type || [];
      // media.listing_type = listing_type.map((l) => l.value);
      this.fetchProperties();
    },

    async fetchAgencyProperties(agencyID) {
      let data = await getProperties(agencyID);
      const list = [];
      for (let i = 0; i < data.length; i++) {
        const inspectionTimes = data[i].inspectionTimes || false;
        if (!inspectionTimes) continue;

        list.push({ ...data[i], imagesPreview: data[i].images.slice(0, 3) });
      }
      return list;
    },
    async fetchProperties() {
      const agency_ids = this.media.agency_ids || [];
      let list = [];
      for (const agencyID of agency_ids) {
        list.push(...(await this.fetchAgencyProperties(agencyID)));
        if (
          list.length >
          this.media.listing_count[0] + this.media.listing_count[1]
        )
          break;
      }
      list = list.filter((item) => this.media.listing_type.includes(item.type));
      this.properties = list.slice(
        this.media.listing_count[0],
        this.media.listing_count[1]
      );

      this.updateItemsPerPage();
    },
    autoSwitchPage() {
      setInterval(() => {
        this.page = this.page < this.pageCount ? this.page + 1 : 1;
      }, (this.media?.duration || 30) * 1000);
    },
    updateItemsPerPage() {
      const containerHeight = window.innerHeight; // Subtract some height for title, pagination, and margins
      return parseInt(containerHeight / 100);
    },
  },
  mounted() {
    if (window.localMediaData) this.loadData(window.localMediaData);
    this.autoSwitchPage();
    window.addEventListener("resize", this.updateItemsPerPage);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateItemsPerPage);
  },
};
</script>

<style>
.v-application {
  font-size: 24px; /* Set default font size for TV readability */
  font-family: Montserrat !important;
}

.v-application {
  background-color: var(--v-background-base) !important;
  color: var(--v-onBackground-base) !important;
}
.title-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.v-card {
  margin-bottom: 16px;
  border-radius: 32px; /* More rounded corners for Material 3 */
}

.v-card-title {
  font-size: 24px; /* Smaller font size for titles to fit more cards */
  font-weight: 500; /* Font weight for M3 */
}

.v-card-subtitle {
  font-size: 20px; /* Smaller font size for subtitles to fit more cards */
  font-weight: 400; /* Font weight for M3 */
}

.v-card-text {
  font-size: 18px; /* Smaller font size for text to fit more cards */
  font-weight: 400; /* Font weight for M3 */
}

.v-btn {
  border-radius: 32px; /* More rounded corners for buttons */
}

.v-application,
.title * {
  font-family: Montserrat !important;
}
</style>
