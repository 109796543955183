<template>
  <v-img :src="imageUrl" :lazy-src="lazySrc" />
</template>

<script>
export default {
  props: {
    loadThunbOnly: Boolean,
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageUrl: "",
      lazySrc: "",
    };
  },
  async created() {
    this.updateImageUrl();
  },
  watch: {
    loadThunbOnly(newVal) {
      if (!newVal) {
        this.updateImageUrl();
      }
    },
  },
  methods: {
    async updateImageUrl() {
      this.lazySrc = await this.loadImage(this.src, 1);
      if (!this.loadThunbOnly)
        this.imageUrl = await this.loadImage(this.src, 300);
    },
    async loadImage(src, size) {
      const cacheKey = this.getCacheKey(src, size);
      let cachedUrl = this.getCachedImage(cacheKey);

      if (!cachedUrl) {
        cachedUrl = await this.getImage(src, size);
        this.setCachedImage(cacheKey, cachedUrl);
      }

      return cachedUrl;
    },
    async getImage(src, size) {
      const endpoint = `https://australia-southeast1-easysignage.cloudfunctions.net/resizeImage/?urlImage=${src}&maxWidth=${size}&maxHeight=${size}`;
      const response = await fetch(endpoint);
      const data = await response.json();
      return data.publicUrl;
    },
    getCachedImage(cacheKey) {
      const cachedData = localStorage.getItem(cacheKey);

      if (cachedData) {
        const { publicUrl, timestamp } = JSON.parse(cachedData);

        // Check if cache is older than 24 hours
        if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
          return publicUrl;
        } else {
          // Remove expired cache
          localStorage.removeItem(cacheKey);
        }
      }

      return null;
    },
    setCachedImage(cacheKey, publicUrl) {
      const data = {
        publicUrl,
        timestamp: Date.now(),
      };

      localStorage.setItem(cacheKey, JSON.stringify(data));
    },
    getCacheKey(src, size) {
      return `image_${src}_maxWidth${size}_maxHeight${size}`;
    },
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
