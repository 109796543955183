import Vue from "vue";
import Router from "vue-router";
import PropertyList from "@/components/PropertyList.vue";
import PropertyDetails from "@/components/PropertyDetails.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "PropertyList",
      component: PropertyList,
    },
    {
      path: "/property/:id",
      name: "PropertyDetails",
      component: PropertyDetails,
      props: true,
    },
  ],
});
