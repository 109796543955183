import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    options: {
      customProperties: true, // Enables the use of custom CSS variables
    },
    themes: {
      light: {
        primary: "#1976D2", // Example default primary color
        secondary: "#424242", // Example default secondary color
        accent: "#82B1FF", // Example default accent color
        onBackground: "#000000", // Default font color
        background: "#FFFFFF", // Default background color
      },
    },
  },
});

export default vuetify;
