const apiKey = "AIzaSyDXfUOMtvvXFxzBBlawtwfoufaWHhc96Bs";
const projectId = "easysignage";
// Function to format Firestore data to match Firestore SDK structure
function reformatFirestoreDocument(data) {
  const parseValue = (value) => {
    if (!value) return null;
    if (value.stringValue !== undefined) return value.stringValue;
    if (value.integerValue !== undefined)
      return parseInt(value.integerValue, 10);
    if (value.doubleValue !== undefined) return value.doubleValue;
    if (value.booleanValue !== undefined) return value.booleanValue;
    if (value.timestampValue !== undefined)
      return new Date(value.timestampValue);
    if (
      value.arrayValue !== undefined &&
      value.arrayValue.values !== undefined
    ) {
      return value.arrayValue.values.map(parseValue);
    }
    if (value.mapValue !== undefined && value.mapValue.fields !== undefined) {
      return Object.fromEntries(
        Object.entries(value.mapValue.fields).map(([k, v]) => [
          k,
          parseValue(v),
        ])
      );
    }
    return null;
  };

  const formattedData = {
    id: data.name ? data.name.split("/").pop() : null,
    createdAt: data.createTime ? new Date(data.createTime).toISOString() : null,
    updatedAt: data.updateTime ? new Date(data.updateTime).toISOString() : null,
    ...Object.fromEntries(
      data.fields
        ? Object.entries(data.fields).map(([key, value]) => [
            key,
            parseValue(value),
          ])
        : []
    ),
  };

  return formattedData;
}
export const getProperties = async (id) => {
  const endpoint = `https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/realestate-data/agents/${id}?key=${apiKey}`;
  console.log("id", endpoint);
  try {
    const response = await fetch(endpoint);

    if (!response.ok) {
      throw new Error("Network response was not ok" + response.statusText);
    }

    const data = await response.json();
    return data.documents.map((doc) => {
      return reformatFirestoreDocument(doc);
    });
    // Process the data as needed
  } catch (error) {
    console.error("Error fetching data from Firestore:", error);
  }
};
